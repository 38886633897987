export type PlanDetail = {
  name: string;
  price: string;
  plan_id: string;
  no_trial_plan_id: string;
  description: string;
  features: string[];
};

export const currencySymbols: { [key: string]: string } = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
  // Add more currencies as needed
};

export const getCurrencySymbol = (currencyCode: string): string => {
  return currencySymbols[currencyCode] || currencyCode; // Fallback to code if symbol not found
};

export const FREE_PLAN_ID = "creative-os-free-plan";

export const STANDARD_PLAN = {
  name: "Creative Plan",
  price: "$49",
  plan_id: "pln_creative-os-standard-hoq20qmr",
  no_trial_plan_id: "creativeos-standard-no-trial",
  description: "Perfect for brands/agencies running ads",
  features: [
    "1000+ Proven Ad Templates",
    "Ad Inspiration Feed",
    "Curated Brand List",
  ],
};

export const PRO_PLAN = {
  name: "Pro Plan",
  price: "$99",
  plan_id: "pln_creative-os-pro-hdpx0qjo",
  no_trial_plan_id: "creativeos-pro-no-trial",
  description: "Perfect for brands/agencies looking for full funnel creative",
  features: [
    "Everything in Creative +",
    "Proven Email Templates",
    "Proven Landing Page Templates",
    "Expert Curated Ad Templates",
  ],
};

export const plans: PlanDetail[] = [STANDARD_PLAN, PRO_PLAN];
